import React , { useState, useContext, useEffect } from 'react'
import { Box, Divider, Paper, Typography } from '@mui/material'
import {  List, Stack,ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { StorefrontContext, }  from "../context/StorefrontProvider";
import ProductCard from './storefrontComponents/ProductCard';
// import * as helpers from '../../utils/helpers'
import {QauRaTagFilter} from '../utils/helpers'
import useWindowDimensions from "../hooks/windowDimensions";


const CollectionView = (props) => {
    const { modalOpen, setModalOpen,cart,
          setCart,order, view, setView, subView , setSubView} = useContext(StorefrontContext);
    const { items, collection, isFeatured, conf } = props//shop?.collections?.filter((collection)=> collection?.name?.toString() === subView)[0]
    const header_pic = 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1713992003/h7gqpgoknqs8ijweazub.jpg'
    const [newMap, setNewMap] = useState([])
   const { height, width } = useWindowDimensions();
   
    const catMap = async () =>{
      try{
        console.log('[catMap] [Collection]:', collection)
        console.log('[catMap] [items]:', items)
        
        const arr = items?.map((item,i)=>{
          
          console.log('[catMap] [item]listing-------:', item?._id)
          if(collection?.items?.includes(`${item?._id}`) === true){
            console.log('[catMap] [item] IN LIST?-------:', item?._id)
           
            return item
        }
      
      })
        if(arr?.length > 0){
            await setNewMap(arr)
            console.log('[catMap] Category:',arr)
          return arr
        }else{ 
          console.log('[catMap]----No items in collection-----')
          return
        }
      }catch(err){console.log(err)}
    }
   
    useEffect(()=>{
    //    if(collection?.name !== subView){
    //   catMap()
    // }
    },[subView])
    // const qauRaTagFilter = (text,string='QauRa')=>{

    //   // let newText = text
    //         if(text.includes(string)){  
    //            const regex = new RegExp(`\\b${string}\\b`, 'gi');
    //          return text.replace(regex, <b> <a  href="https://qaura.pro"  target="_blank" style={{textDecoration:'none'}}>QauRa</a></b>)
    //         }
    //  else{ return text }

    // }
    
    
  return (
     <Box  sx={{minWidth:'250px',width:'100%',fontFamily:'Gilroy, sans-serif', padding:'0.5rem'}}>
        {/* <Typography  variant='h3' sx={{textAlign:'left'}}>Menu</Typography>   */}
        <Box sx={{position:'relative',margin:'auto', alignItems:'center', textAlign:'center',
        display:'flex',width:'100%'}}>
      {(isFeatured || collection?._id.toString() === conf?.featured_collection)? <Box 
        sx={{position:'absolute', top: width >=600? '-1.5rem':'-3rem', left: width >=600 ?'0.5rem':'-1.5rem',
           padding:'0.5rem',
           display:'flex',
           flexDirection:'column',
           gap:'0.125rem',
            alignItems:'center', 
            alignContent:'center',
            justifyContent:'center',
            color:'#FFF',
            height: width >=600 ?'5.5rem':'4rem',
            width: width >=600 ?'5.5rem':'4rem',
            zIndex:990,
            // boxShadow:'0px -1px 2px 1px rgba( 0, 0, 0, 0.5)',
            // background:'url("/assets/Sticker.svg")',
  
            // border:'1px solid #000',
             borderRadius:'50px',transform:'rotate(-25deg)',}}>

            <Box 
            component='img'
            src='/assets/Sticker.svg'
            sx={{position:'absolute',width:'100%',
            height:'100%',zIndex:990}}
            />
          <b style={{fontFamily:'Leckerli One, sans-serif',
            fontSize:width >=600 ?'0.9rem':'0.7rem',
            zIndex:99999}}>
            Featured
            </b>
          <b style={{fontFamily:'Gilroy, sans-serif',
            fontSize: width >=600 ?'0.7rem':'0.6rem',
            marginTop:'0rem',
            zIndex:99999}}>
              collection
            </b>
       
        </Box>:''
        }
          <Stack sx={{textAlign:'center',margin:'auto',gap:'0.5rem',padding:'0.5rem'}}>

            <h1 variant='h2' style={{margin:'auto',textTransform:'capitalize',fontFamily:'Gilroy, sans-serif'}}>
              {/* {subView} */}
              {collection?.name}
            </h1> 
            {collection?.tagline && 
              <b
                // ref={collectionTag}
                style={{width:'70%', minWidth:'300px',margin:'auto',fontFamily:'Gilroy, sans-serif'}}>
                {/* {QauRaTagFilter(collection?.tagline)} */}
                ( <QauRaTagFilter text={collection?.tagline} /> ) 

              </b> 
            
              }
            {collection?.description && 
              <Typography variant='p'
                // ref={collectionTag}
                sx={{width:'70%', minWidth:'300px',margin:'auto'}}>
                {/* {QauRaTagFilter(collection?.description)} */}
                 <QauRaTagFilter text={collection?.description} />

              </Typography> 
            
              }
          </Stack>
        </Box>
        <Divider />
        <Box sx={{display:'flex',flexDirection:'row',width:'100%',flexWrap:'wrap',justifyContent:'space-evenly', gap:'0.5rem'}}>

        {items && items?.map((ele,i)=>{

          //  console.log('collection test',collection)
          
           if(collection?.items?.includes(`${ele?._id.toString()}`) === true){
            // console.log('[collection items]:',ele?.name)
            return <ProductCard key={i} item={ele} size={'md'}/>
           }else{return}
        })}
        </Box>
    </Box>
  )
}

export default CollectionView