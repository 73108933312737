import React , { useState, useContext, useRef ,useEffect} from 'react'
import { Box, Button, Divider, Typography } from '@mui/material'
import {  List, Stack,ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { StorefrontContext, }  from "../../context/StorefrontProvider";
import useWindowDimensions from "../../hooks/windowDimensions";
import ReviewSection from './ReviewSection';
import SizeSelector from './SizeSelector';

import {magnify} from '../../utils/magnify'
import PicFullView from './PicFullView';
import AddToCartButton from './AddToCartButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {QauRaTagFilter} from '../../utils/helpers'


const ProductView = (props) => {
    const { modalOpen, setModalOpen,cart, setCurrentProduct,currentProduct,
        shop,
        setCart,order, view, setView,subView , setSubView} = useContext(StorefrontContext);
    const collections = props.collections
    const { isRexPlayer } = props
    const [ showDetails, setShowDetails ] = useState(true);
    const [ showPic, setShowPic ] = useState(false);
    const header_pic = 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1713992003/h7gqpgoknqs8ijweazub.jpg'
    const item = currentProduct
    const { height, width } = useWindowDimensions();
    const [ showAddToCart, setShowAddToCart ] = useState(false)
    const [ currentImage, setCurrentImage ] = useState(item?.images[0])

    const ref = useRef(null)

    const handleViewSelect = (e) =>{
        setView(e.target.value)
    }
    
    
    // magnify("myimage",3)
    useEffect(() => {
        ref.current.addEventListener('onMouseEnter',()=>{
            // setCurrentImage(true)
            
        })
        ref.current.addEventListener('onMouseLeave',()=>{
            setShowAddToCart(false)
            
        })
    }, [ref,currentProduct])
    
    useEffect(() => {
       console.log('[ProductView] currentProduct:', currentProduct)
    }, [currentImage,showPic])

    
  return (
    <Box sx={{
            positon:'relative',
            height:width >= 750 ? '100%':'100%', 
            margin:'2rem auto',
            // maxWidth: width >= 750 ? '800px' :'500px',
            width: '100%',
            overflowY:'scroll',
            display:'flex', flexDirection:'column', 
            alignItems:'center',
            // border:'1px solid red',
            padding:'0.5rem',
            
            padding:'1rem',
            gap:'1.75rem', justifyContent:'center', textAlign:'center'}}>
          
           {currentImage && <PicFullView func={setShowPic} pic={currentImage} value={showPic}/>}
             <Box  sx={{positon:'relative',width: width >= 750 ? '80%':'350px',overflow:'hidden', display:'flex', flexDirection: width >= 750?'row':'column'}}>
                <Box ref={ref} sx={{positon:'relative',width: width >= 750 ? '60%':'350px',
                    overflow:'hidden',display:'flex', margin: 'auto'}}>
                    <Stack sx={{display:'flex', flexDirection:'column', 
                    justifyContent:'space-evenly',width:'15%', margin: 'auto'}}>
                        {item?.images && item?.images?.map((img,i)=>{
                            return <img src={img} 
                                     key={i}
                                    onClick={async (e) =>{
                                        console.log('new image:', img)
                                        await setCurrentImage()
                                        await setCurrentImage(img)
                                    }}
                                    style={{width:width >= 750 ? '70px':'70px', cursor:'pointer', 
                                        borderRadius:'5px',
                                        border:`1px solid ${ img === currentImage ? 'blue':'#FFF'}`
                                    }} />
                            })}
                    </Stack>
                    <Box sx={{flex:1,cursor:'zoom-in'}} className="img-magnifier-container">
                        <img src={currentImage ? currentImage:item?.images[0]} 
                        id="myimage"
                        alt={`${item?.name} ${item?.subtitle} product image`} 
                        style={{ width:width >= 750 ? '100%':'100%', maxWidth:width >= 750 ? '300px':'250px'}}
                        onClick={()=>{
                            setShowPic(true)
                        }}
                        />
                    </Box>
                </Box>


                    <Stack sx={{
                        positon:'relative', margin: 'auto',
                        width: width >= 750 ? '40%':'100%',
                        maxWidth:'250px',
                        // fontSize:width >= 750?'inherit':'0.75rem',
                        justifyContent:'center', textAlign:'left', gap:'0rem'}}> 

                    <Typography variant='h4' sx={{marginBottom:'0px',fontFamily:'Glass Antiqua'}}>
                        {item?.name}
                    </Typography>

                    <Typography variant='p' sx={{marginTop:'0px'}}>
                   { item?.subtitle ?` ( ${item?.subtitle} )`:''}
                    </Typography> 

                    <Typography variant='h6' sx={{position:'relative'}}>
                       ${item?.price}.<span style={{fontSize:"0.75rem",position:'absolute',top:'0.2rem'}}>00</span>
                    </Typography>
                    
                    {item?.description && <Typography sx={{maxWidth:'350px', wordWrap:'break-word', wordBreak:'break-word'}}>
                        {/* {item?.description} */}
                <QauRaTagFilter text={item?.description} />
                    </Typography>}
                    {( showDetails ) && <u style={{cursor:'pointer'}} onClick={()=>setShowDetails(!showDetails)}>less...</u>}
                    {( showDetails) ? <List  >
                        {item?.details?.map((d, index) => {
                        return <ListItem>{d}</ListItem>
                    })}
                    </List> 
                    : <u style={{cursor:'pointer', marginBottom:'0.75rem',zIndex:999}} onClick={()=>setShowDetails(!showDetails)}>more...</u>}

                    <Stack sx={{display:'flex', flexDirection:'column', gap:'1.5rem', alignSelf: width >= 750 ? 'left': 'center'}}>
                        {/* <Button variant='outlined' sx={{borderColor: '#757575', color:'#000'}}
                        onClick={()=>{
                            setCurrentProduct(item)
                            setView('product')
                        }}
                        
                        >View Product</Button> */}
                         {item?.sizes && <SizeSelector sizes={item?.sizes}/>}
                        <AddToCartButton item={item} func={setModalOpen}  view='product-view'/>
                        
                        {/* <Button variant='outlined' sx={{borderColor: '#757575', color:'#000'}} onClick={(e)=>{
                            console.log('[Add to Cart Click]')
                            setModalOpen(true)
                        }}>Add to Cart</Button> */}
                    </Stack>    
                    </Stack>
                    {/* <Divider /> */}
        
                </Box>
                    <Box  sx={{
                        margin:'1rem',
                        border: '1px solid #000',
                        borderLeft: 'none',
                        borderRight: 'none',
                        overflow:'hidden'
                        }}>
                            <ReviewSection conf={{...shop?.config}} items={shop?.items} 
                                setModalOpen={setModalOpen} view={'product'} item={item}/>
                        </Box>
                </Box>
  )
}

export default ProductView