import React from 'react'
import MenuContent from './MenuContent'
import CloseButton from '../../components/CloseButton'
import { Modal,Box, Container } from '@mui/material'

const MenuModal = (props) => {
    const {setMenuModalOpen,menuModalOpen, collections} = props
  return (
      <Modal
            open={menuModalOpen}
            onClose={()=>setMenuModalOpen(!menuModalOpen)}
            >
            <Container sx={{zIndex:999}}>
                <Box sx={{ 
                    display:'flex',
                    flexDirection:'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height:'100%',
                    backgroundColor:'rgba( 255,255,255,0)',
                    borderRadius:'10px',
                    border:'2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    justifyContent:'center',
                    textAlign:'center',
                    alignItems:'center',
                    gap:'0.5rem',
                    padding:'0.5rem'
                    
                    }}>
                        <Box sx={{position:'absolute', top:'0.5rem', right:'0.5rem',zIndex:9999999}}>
                            <CloseButton func={setMenuModalOpen} val={menuModalOpen}
                        
                            />
                        </Box>
                        <Container sx={{display:'flex',background:'#FFF', borderRadius:'8px',
                            border:'2px solid #000',width:'65%', justifyContent:'center'
                        
                        }}>

                            <MenuContent collections={collections} func={setMenuModalOpen} menuOpen={menuModalOpen} />
                        </Container>
                   
                    </Box>
            </Container>
        </Modal>
  )
}

export default MenuModal