import { Container, Modal,Box, Button,
     Input, Typography, TextField, Stack } from '@mui/material'
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom';

import CloseButton from '../../../components/CloseButton'

const BioModal = (props) => {
    const { bioModalOpen, setBioModalOpen } = props
  return (
     <>
        <Modal 
            open={bioModalOpen}
            // open={true}
            onClose={()=>setBioModalOpen(!bioModalOpen)}
            >
            <Container sx={{zIndex:999}}>
                <Box sx={{ 
                    display:'flex',
                    flexDirection:'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height:'100%',
                    backgroundColor:'rgba( 0, 0, 0, 0)',
                    backdropFilter:'blur(5px)',
                    borderRadius:'10px',
                    border:'2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    justifyContent:'center',
                    textAlign:'center',
                    alignItems:'center',
                    gap:'0.5rem',
                    padding:'0.5rem',
                    color:'#FFF'
                    }}>
                        <Box sx={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
                            <CloseButton func={setBioModalOpen} val={bioModalOpen}
                            />
                        </Box>
                         <Box sx={{display:'flex',flexDirection:'column',  background:'#000',
                                boxShadow:'0 0 10px rgba(0, 255, 0 ,0.5)',
                                overflow:'hidden',overflowY:'scroll',
                                width:'340px', height:'400px', gap:'0.5rem', color:'#00FF00', borderRadius:'5px'}}>
                            <Box sx={{width:'100%', height:'1.5rem',background:'#F6F6F6'}}>
                         
                                <Typography sx={{color: '#858585'}} className='blink'>
                                   <b>Send Some Love!</b> 
                                </Typography>
                                </Box>
                            
                        <Stack 
                            sx={{margin:'auto', width:'200px', height:'200px', 
                            // backgroundColor:'rgba(255,255,255,0.3)', 
                            backdropFilter:'blur(2px)',
                            padding:'0.5rem',justifyContent:'center',
                            borderRadius:'10px',  gap:'0.5rem', alignItems:'center',
                            alignContent:'center', border:'2px solid #00FF00',
                             boxShadow:'0px 0px 15px 5px rgba(0, 255, 0 ,0.5),inset 0px 0px 5px 3px rgba(0, 255, 0 ,0.2)',
                            }}>


                            </Stack>
                            <hr style={{borderColor:'#00FF00', width: '90%', margin:'auto',
                                boxShadow:'0px -2px 15px 5px rgba(0, 255, 0 ,0.5),inset 0px 0px 5px 3px rgba(0, 255, 0 ,0.2)',}}/>
                            <Stack sx={{ display:'flex',flexDirection:'column',marginTop:'1rem', margin:'auto', //border:'1px solid red',
                                textAlign:'end',justifyContent:'end'}}>
                                
                            </Stack>
                            {/* </Link> */}
                            {/* <Typography  variant='caption'>
                                
                                ( Check back soon! )
                            </Typography> */}
                  </Box>
                    </Box>                   
            </Container>
        </Modal>
        
    </>
  )
}

export default BioModal