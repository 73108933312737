import { Container, Modal,Box, Button,
     Input, Typography, TextField, Stack, 
     TabPanel,TabList,
     Tab,
     Tabs,
     Tooltip,Pagination
    } from '@mui/material'
import React, { useState ,useContext} from 'react'
import { Link, useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { AppContext } from "../context/AppProvider";
import { QRCodeContext } from "../context/QRCodeProvider";

import CloseButton from '../components/CloseButton'
import RefreshIcon from '@mui/icons-material/Refresh';
import * as API from '../utils/APICalls'
import * as helpers from '../utils/helpers'
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import BadgeIcon from '@mui/icons-material/Badge';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import QrCode from '@mui/icons-material/QrCode';


const OrderDataGrid = ({orders}) => {
    // const {orders, handleOrderSelect} = props 
    const { appData, updateAppData, setCurrentOrder,
        subView, setSubView,
        currentProduct } = useContext(AppContext)
        const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);
    const  handleOrderSelect = async (order) =>{
        try{
          console.log('[OrderSelect]:---order---',order)
    
          setCurrentOrder(order)
          // if(order?.qr_id){
    
            const res = await API.fetchQRByID(order?._qr_id)
              if(res?.data){
                updateQRCodeData(res?.data)
              }else{
                console.log('[Order click]:---NO QR Available---')
      
              }
            // console.log('[Order click]:', order)
            updateAppData({
              modalView:'review-order'
            })
          // }
        }catch(err){console.log(err)}
      }
    const shareColumns = [
        { field: '_id', headerName: 'id', minWidth: 2 },
        { field: 'customer?.email', headerName: 'Email', minWidth: 120,maxWidth:200 },
      {
        field: 'createdAt',
        headerName: 'Date',
        width: 80,

        // editable: true,
        renderCell: (params) => {
    const date = new Date(params.value)
    let formatted_date = date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: '2-digit'
            })
        return formatted_date
          
        },
      },
      {
        field: 'customField',
        headerName: 'Items',
        minWidth: 60,
        maxWidth: 70,
        // valueGetter: (params) => {
        renderCell: (params) => {
        const type = params?.row?.type;
        const qr_id = params?.row?.qr_id;
        const asset_url = params?.row?.asset_url;
        const printFile = params?.row?.printFile;
        return (  <span style={{display:'flex',flex:1,justifyContent:'space-evenly',margin:'auto'}}>
                    {type === 'music'&& <MusicNoteIcon sx={{width: '1rem'}}/>}
                    {qr_id !== 'n/a'&& <QrCode sx={{width: '1rem',color: !qr_id ? 'red':'black'}}/>}
                    {asset_url !== 'n/a' && <BadgeIcon sx={{width: '1rem',color: !asset_url ? 'red':'black'}}/>}
                    {(printFile !== 'n/a' && type !== 'music') && <DriveFileMoveIcon sx={{width: '1rem',color: !printFile ? 'red':'black'}}/>}
                </span>)
    },
  },
     
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 60,
        renderCell:(params) =>{
                    return(
                        <span  style={{color: helpers.orderColorCheck(params.value)}}
                        >
                            {params?.value}
                        </span>
                    )}
        // renderCell: (params) => {
        // //   if (params.value === 'comp') {
        //     return (
        //       <Button
        //         variant="contained"
        //         color="primary"
        //         size="small"
        //         // onClick={() => handleButtonClick(params.row)}
        //       >
        //         review
        //       </Button>
        //     );
        //   } 
    
        //   return (
        //     <div style={{ color: params.value !== 'resend' ? '#FF00FF' : '#00ff00', display:'flex',fontSize:'0.85rem',
        //             alignContent:'center',
        //             alignItems:'center',
        //             fontFamily:'LCD2', //className='blink'
        //             justifyContent:'center'
        //             }}>
        //       {params.value}  <img style={{ alignSelf:'center'}} className='coin-rotate'
        //                  src={'/assets/RexCoin.png'} width='20px' height='auto'/>
        //     </div>
        //   )
        //   return params.value;
        },
      {
        // field: 'isComplete',
        headerName: 'Action',
        width: 80,
        // editable: true,
        renderCell: (params) => {
        //   if (params.value === 'resend') {
            return (
              <Button
                variant="contained"
                color="primary"
                size="small"
                 onClick={() => {
                    console.log('row:',params.row)
                    const obj = params.row
                    handleOrderSelect(obj)}}
        
              >
                review
              </Button>
            );
          } 
    
      },


      ];
    // console.log('orders:', orders) 
    return (
    <>
        <DataGrid
            rows={orders}
            columns={shareColumns}
             getRowId={(row) => row._id}
            initialState={{
            pagination: {
                    paginationModel: {
                    pageSize: 15,
                    // color:'#00ff00'
                    },
                },
                }}
                components={{
                Pagination: (props) => (
                <Pagination {...props} className="custom-pagination" />
                ),}}
            // getRowClassName={() => 'share-row-text-color'}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          
            />
    </>
  )
}

export default OrderDataGrid