import React , { useState, useContext } from 'react'
import { Box, Divider, Typography } from '@mui/material'
import {  List, Stack,ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { StorefrontContext, }  from "../../context/StorefrontProvider";
import { Brush, ConnectWithoutContact, Home, PrecisionManufacturing } from '@mui/icons-material';
import QrCodeIcon from '@mui/icons-material/QrCode';
import BookIcon from '@mui/icons-material/Book';
import CollectionsIcon from '@mui/icons-material/Collections';
import * as helpers from '../../utils/helpers'

const MenuContent = (props) => {
    const { modalOpen, setModalOpen,cart,
        setCart,order, view, setView,subView , setSubView} = useContext(StorefrontContext);
    const collections = props.collections
    // const  props.func = props.func()
    const header_pic = 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1713992003/h7gqpgoknqs8ijweazub.jpg'
    
    const collectionTypes =  [...new Set(collections?.map((item) => item?.type))]
    const handleViewSelect = (e) =>{
        setView(e.target.value)
    }
  return (
     <Box  sx={{minWidth:'250px',fontFamily:'Gilroy, sans-serif', padding:'0.5rem'}}>
        {/* <Typography  variant='h3' sx={{textAlign:'left'}}>Menu</Typography>   */}
        <Box 
        onClick={()=>{setView('home')}}
        sx={{margin:'auto', alignItems:'center', display:'flex',width:'100%',cursor:'pointer',}}>
            <img src={header_pic} width='100px'style={{opacity:'0.65', alignSelf:'center',margin:'auto'}} />
        </Box>
        <Divider />
        <Box  sx={{padding:'0.5rem'}}>
        <List  >
            <ListItem sx={{alignContent:'center',alignItems:'center',gap:'0.5rem'}}>

                        <Home />
                    <Typography variant='h6'
                        onClick={(e)=>{
                            setView('home')
                            console.log('[View]',view)
                            props.func(false)
                        }}
                            sx={{alignContent:'center',alignItems:'center',cursor:'pointer',"&:hover": {borderBottom:'1px solid #757575'}}}
                        >
                        Home
                    </Typography>
            </ListItem>
              <ListItem sx={{alignContent:'center',alignItems:'center',gap:'0.5rem'}}>
                <CollectionsIcon />
                <Typography variant='h6'>
                    Collections
                </Typography>
            </ListItem>
            <Box sx={{marginLeft:'1.5rem',textAlign:'left'}}>

            {collectionTypes?.sort()?.map((type,j)=>{

                return(
                    < >
                    {/* <Box style={{dsiplay:'flex',width:'100%'}}> */}
                    <Typography>{type[0].toUpperCase() + type.substring(1)}</Typography>
                    {/* <Box>

                    <img
                        src={'/assets/ShirtIcon_cer.svg'}
                        className='button-woddle'
                        style={{cursor:'pointer',width:'2rem'}}
                        onClick={(e)=>{

                            // window.open(`https://parallaxr.shop/${listEmail}/${item?.paired_merch_id ?item?.paired_merch_id:''}`,'_blank')
                        }} />
                    </Box>
                    </Box> */}
                    { collections?.map((ele,i)=>{
                        if(ele?.type === type){
                            
                            return <ListItem key={i}
                            
                                onClick={(e)=>{
                                try{
                                    console.log('[subview ]', ele?.name?.toString())
                                    setSubView(ele?.name?.toString())
                                    setView('collection')
                                    console.log('[subview]', subView)
                                    props.func(false)
                                }catch(err){console.log(err)}
                            }}
                            sx={{cursor:'pointer',textTransform:'capitalize',
                                gap:'0.5rem',
                                "&:hover": {borderBottom:'1px solid #757575'}}}
                            >{ele?.name}
                            <ListItemIcon>
                           {!helpers.itemNameFilter(ele,'purpose driven')&&
                           <Tooltip title='artwork'>

                             <Brush/>
                           </Tooltip>
                            }
                           {!helpers.itemNameFilter(ele,'the basics')&& 
                              <Tooltip title='unique QR'>
                                <QrCodeIcon/>
                            </Tooltip>
                            }
                            </ListItemIcon>
                            </ListItem>
                        }else{
                            return
                        }
                    })}
                    </>
                )
            })
            }
            </Box>
        </List>
        <ListItem sx={{alignContent:'center',alignItems:'center',gap:'0.5rem'}}>
            <BookIcon/>
            <Typography variant='h6'
                onClick={(e)=>{
                    setView('about')
                    console.log('[View]',view)
                    props.func(false)
                }}
                    sx={{cursor:'pointer',"&:hover": {borderBottom:'1px solid #757575'}}}
                >
                    About The Brand
            </Typography>
        </ListItem>

            <ListItem sx={{alignContent:'center',alignItems:'center',gap:'0.5rem'}}>
                <PrecisionManufacturing/>
                <Typography variant='h6'
                onClick={(e)=>{
                    setView('how it works')
                    console.log('[View]',view)
                    props.func(false)
                }}
                    sx={{cursor:'pointer',"&:hover": {borderBottom:'1px solid #757575'}}}
                >
                    How It works
                </Typography>
            </ListItem>

            <ListItem sx={{alignContent:'center',alignItems:'center',gap:'0.5rem'}}>

                <ConnectWithoutContact />
                <Typography variant='h6'
                onClick={(e)=>{
                    setView('contact')
                    console.log('[View]',view)
                    props.func(false)
                }}
                    sx={{cursor:'pointer',"&:hover": {borderBottom:'1px solid #757575'}}}
                >
                    Contact
                </Typography>
            </ListItem>
        </Box>
    </Box>
  )
}

export default MenuContent