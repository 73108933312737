import React, { useEffect, useState } from 'react'
import { Container, Modal,Box, Button, Input, Typography, TextField, Stack, IconButton, InputAdornment, Chip, Divider } from '@mui/material'
import CloseButton from '../../../components/CloseButton'
import * as API from '../../../utils/APICalls'

const UpdateModal = (props) => {
    const { setUpdateModalOpen, updateModalOpen, 
        listener, setListener,artist,setSubsModalOpen,
        item, email, } = props

    const [ preferredName, setPreferredName ] = useState('')


    const inputStyles ={
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
            borderColor: '#00FF00',
            boxShadow:'0px 0px 5px 3px rgba(0, 255, 0 ,0.5),inset 0px 0px 3px 1px rgba(0, 255, 0 ,0.2)',
            },
            '&:hover fieldset': {
            borderColor: '#00FF00',
            boxShadow:'0px 0px 5px 3px rgba(0, 255, 0 ,0.5),inset 0px 0px 3px 1px rgba(0, 255, 0 ,0.2)',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#00FF00',
            boxShadow:'0px 0px 5px 3px rgba(0, 255, 0 ,0.5),inset 0px 0px 3px 1px rgba(0, 255, 0 ,0.2)'
        },
    },
        '& .MuiInputLabel-root': {
            color: '#FF00FF',
            boxShadow:'0px 0px 5px 3px rgba(0, 0, 0 ,0.5),inset 0px 0px 5px 3px rgba(0,0, 0 ,0.5)',
            background:'#000'
        },
        '& .MuiInputBase-input': {
            color: '#00FF00',
        },
        
   }
   function ImageComponent({ iconHtml }) {
    return (
        <div dangerouslySetInnerHTML={{ __html: iconHtml }} />
    );
    }

    const handleNameUpdate = async () =>{
            setListener({
                ...listener,
                email,
                preferredName
            })
            localStorage.setItem('BOCUser', JSON.stringify({
                ...listener,
                email,
                preferredName
            }))
        try{
            const res = await  API.updateListenerName({email,preferredName})
            if ( res ){
                // setListener(res)
                setUpdateModalOpen(false)
                setPreferredName('')
            }
        }catch(err){console.log('[handleNameUpdate] error:',err)}
        // setUpdateModalOpen(false)
    }
   useEffect (()=>{

      },[listener])
  return (
     <>
        <Modal 
            open={updateModalOpen}
            // open={true}
            onClose={()=>setUpdateModalOpen(false)}
            >
            <Container sx={{zIndex:999}}>
                   <Box sx={{position:'absolute', top:'0rem', right:'0rem'}}>
                        <CloseButton func={setUpdateModalOpen} 
                                    value={updateModalOpen}
                        />
                    </Box>
                <Box sx={{ 
                    display:'flex',
                    flexDirection:'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '325px',
                    // height:'180px',
                    height:'fit-content',
                    // minHeight:'550px',
                    backgroundColor:'#000',
                    borderRadius:'10px',
                    border:'2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    justifyContent:'center',
                    textAlign:'center',
                    alignItems:'center',
                    gap:'0.5rem',
                    // padding:'0.5rem',
                    boxShadow:'0 0 10px rgba(0, 255, 0 ,0.5)',
                    color:'#00FF00', borderRadius:'5px',
                    marginBottom:'2rem'
                    }}>
                      <Box sx={{position:'sticky',top:0, left:0, zIndex:999999999,width:'100%', height:'1.5rem',background:'#F6F6F6'}}>                       
                            <Typography sx={{color: '#858585'}} >
                                Profile
                            </Typography>
                        </Box>
                        <Container sx={{display:'flex',
                            position:'relative',
                            padding:'0.5rem',
                            flexDirection:'column',gap:'0.5rem'}}>
                            <Typography style={{fontWeight:'bold',fontSize:'1.25rem',textAlign:'center', color:'#FF00FF'}}>
                            {/* <b style={{color:'#FF00FF'}}>
                             accnt:
                            </b>
                            &nbsp; */}
                            {listener?.email}
                          </Typography>
                          <Divider sx={{borderColor:'#00FF00'}}/>
                        <Stack sx={{gap:'0.5rem', width:'100%',
                            height:'100%',margin:'auto',
                            textAlign:'left' }}>

                          {/* <span style={{marginBottom:'0.75rem',width:'100%'}}>

                            <b style={{color:'#FF00FF',marginBottom:'0.75rem'}}>
                                Rex:&nbsp;
                            </b> */}
                                <span 
                                className='typing' 
                                style={{fontWeight:'bold',width:'fit-content',paddingRight:'0.5rem',
                                    }}>
                                    What do you wish to go by?
                                </span> 
                          {/* </span> */}

                            <TextField label='preferred name'
                            sx={inputStyles}
                            defaultValue={listener?.preferredName}
                            onChange={(e)=>{setPreferredName(e.target.value)}}
                            />
                        
                       {listener?.subscriptions?.length > 0 ?
                       <Stack sx={{position:'relative',justifyContent:'center',
                        boxShadow:'0px 0px 5px 3px rgba(0, 255, 0 ,0.5),inset 0px 0px 3px 1px rgba(0, 255, 0 ,0.2)',
                       gap:'0.5rem',marginTop:'1rem'}}>
                          <b style={{position:'absolute',top:'-0.80rem',left:'0.5rem',
                            height:'1.25rem',
                            padding:'0.125rem',
                            backgroundColor:'#000' }}>
                            subscription:       
                        </b>
                        {artist?.subscriptions.map((t,i)=>{
                            const tierCheck = listener?.subscriptions?.find((s) => (s?.sku?.toString() === t?.sku?.toString() && s?.isActive ))
                             const date = new Date(tierCheck?.start_date)
                            let formatted_date = date.toLocaleDateString('en-US', {
                                    month: '2-digit',
                                    day: '2-digit',
                                    year: '2-digit'
                                    })
                            return(
                                <>
                                {tierCheck && <Box sx={{display:'flex',
                                        justifyContent:'space-between', 
                                         padding:'0.75rem', 
                                        border:'2px solid #00FF00',
                                        borderRadius:'5px',
                                        gap: '0.5rem'}} >
                                    {/* <Box sx={{
                                        width:'100%', 
                                        display:'flex',
                                        padding:'0.5rem', 
                                        border:'2px solid #00FF00',
                                        borderRadius:'5px',
                                        gap: '0.5rem'
                                        }}> */}
                                    <Box sx={{display:'flex',
                                                width:'70px',
                                                height:'70px',
                                                flexDirection: 'column',
                                                border:'2px solid #00FF00', 
                                                boxShadow:'0px 0px 5px 2px rgba(0, 255, 0 ,0.5),inset 0 0 3px 2px rgba(0, 255, 0 ,0.2)',
                                                borderRadius:'8px', 
                                                background:'rgba(255,255,255,1)',
                                                // padding: '0.25rem',
                                                cursor:'pointer',
                                                textAlign:'center',
                                                justifyContent:'center',
                                                alignSelf:'center',
                                                '&:hover': {background:'rgba(144, 254, 195,0.5)'},
                                            
                                            }}>

                                            <ImageComponent  iconHtml={t?.icon} />
                                        </Box>
                                            <Stack sx={{alignSelf:'center'}}>
                                                <span style={{display:'flex',gap:'0.25rem',position:'absolute', top:'0.25rem', right:'0.5rem',alignItems:'center'}}>
                                                <div className='blink'
                                                    style={{backgroundColor:"#00FF00",
                                                    boxShadow:'0px 0px 15px 5px rgba(0, 255, 0 ,0.5)',
                                                    margin:0,
                                                    borderRadius:'50px',width:'0.5rem',height:'0.5rem'}}>&nbsp;</div>
                                                <span>
                                                active
                                                </span> 
                                                </span>
                                                <span><b>tier:</b> {tierCheck?.title}</span>
                                                <span><b>sku:</b> {tierCheck?.sku}</span>
                                                <span><b>price:</b> {tierCheck?.price > 0 ?`$${tierCheck?.price}/${tierCheck?.rate}.`:'Free'}</span>
                                                <span><b>start:</b> {formatted_date}</span>
                                            </Stack>
                                    {/* </Box> */}
                                    </Box>}
                                </>
                            )
                        })}
                        </Stack>
                        :
                        <Stack sx={{margin:'0.5rem auto', color:'#FF00FF', marginBottom:'-0.75rem',width:'100%',}}>
                            <b style={{//position:'absolute',top:'-0.80rem',left:'0.5rem',
                                height:'1.25rem',
                                padding:'0.125rem',
                                backgroundColor:'#000' }}>
                                subscription:       
                            </b>
                            <b className='blink'
                                style={{margin:'auto',color:'#00FF00'}} >currently unsubscribed</b>
                        </Stack>
                        
                        }
                    <Stack sx={{position:'relative', marginTop:'0.75rem',
                        cursor:'pointer',padding:'0.75rem',
                        
                        justifyContent:'center',gap:'0.5rem'}}
                        onClick={()=>{
                            setSubsModalOpen(true)
                        }}
                        >
                        <b style={{position:'absolute',top:'-0.125em',left:'1.25rem',
                            height:'1.25rem',
                            padding:'0.125rem',
                            backgroundColor:'#000' }}>
                            upgrade?       
                        </b>
                         <Box sx={{display:'flex',
                                        justifyContent:'space-between', 
                                         padding:'0.5rem', 
                                        border:'2px solid #00FF00',
                                        borderRadius:'5px',
                                         boxShadow:'0px 0px 5px 3px rgba(0, 255, 0 ,0.5),inset 0px 0px 3px 1px rgba(0, 255, 0 ,0.2)',
                                        gap: '0.5rem'}} >
                                    {/* <Box sx={{
                                        width:'100%', 
                                        display:'flex',
                                        padding:'0.5rem', 
                                        border:'2px solid #00FF00',
                                        borderRadius:'5px',
                                        gap: '0.5rem'
                                        }}> */}
                                   
                                               {artist?.subscriptions.map((t,i)=>{
                            const tierCheck = listener?.subscriptions?.find((s) => (s?.sku?.toString() === t?.sku?.toString() && s?.isActive ))
                            return(
                                <>
                                {!tierCheck && 
                                    <Box
                                    className={(i+1)%2 ? 'blink':'blink-2'}
                                         sx={{display:'flex',
                                                position:'relative',
                                                width:'75px',
                                                height:'75px',
                                                overflow:'hidden',
                                                // flexDirection: 'column',
                                                border:'2px solid #00FF00', 
                                                boxShadow:'0px 0px 5px 2px rgba(0, 255, 0 ,0.5),inset 0 0 3px 2px rgba(0, 255, 0 ,0.2)',
                                                borderRadius:'8px', 
                                                background:'rgba(255,255,255,1)',
                                                // padding: '0.25rem',
                                                cursor:'pointer',
                                                textAlign:'center',
                                                alignContent:'center',
                                                justifyContent:'center',
                                                alignSelf:'center',
                                                '&:hover': {background:'rgba(144, 254, 195,0.5)'},

                                            }}>

                                            <ImageComponent style={{alignContent:'center'}} iconHtml={t?.icon} />
                                            <p style={{position:'absolute', bottom:'0',
                                                padding:'0.125rem',
                                                backgroundColor:'#000',fontSize:'0.9rem',
                                                color:'#FF00FF',width:'100%' }}>{t?.title}</p>
                                    </Box>}
                                </>
                            )
                        })}
                                      
                                    </Box>
                                         <p style={{margin:'auto'}}>
                                             lets take it to another level.
                                        </p>
                        
                        </Stack>


                        {listener?.subscriptions?.legnth? listener?.subscriptions[0]?.sku:''}
                        <Box sx={{
                            // position:'fixed', bottom:'0.5rem', left:'50%',
                            // transform:'translate(-50%,0)',
                            display:'flex', width:'80%',margin:'0.5rem auto',
                             justifyContent:'space-between'}}>

                            <Button variant='outlined' 
                                // sx={{position:'fixed', bottom:'0.5rem', right:'0.5rem'}}
                                onClick={()=>setUpdateModalOpen(false)}
                                 sx={{ color:'#FF00FF'}}
                                 >
                                cancel
                            </Button>
                            <Button variant='outlined' 
                            // disabled={true}
                            disabled={(listener?.preferredName?.toString() === preferredName?.toString() || preferredName === '')? true :false}
                                sx={{ color:'#FF00FF'}}
                                // sx={{position:'fixed', bottom:'0.5rem', right:'0.5rem'}}
                                onClick={async ()=>{
                                   await handleNameUpdate()
                                }}
                            >
                                update
                            </Button>
                        </Box>
                        </Stack>
                        </Container>
                    </Box>
                    
            </Container>
        </Modal>
        
    </>
  )
}

export default UpdateModal