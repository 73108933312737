import { Avatar, Box, Card, Skeleton, Typography } from '@mui/material'
import { AppBar,Link, CardMedia, List, ListItem, ListItemIcon, ListItemText, Modal, Tooltip } from '@mui/material';
import React, {useState , useContext, useEffect} from 'react'
import ExploreIcon from '@mui/icons-material/Explore';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import ShareIcon from '@mui/icons-material/Share';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import StarIcon from '@mui/icons-material/Star';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import NavBar from './NavBar';
// import CheckoutModal from '../../components/CheckoutModal';
import CheckoutModal from './CheckoutModal'
import { StorefrontContext, StorefrontProvider } from "../../context/StorefrontProvider";
import { AspectRatio } from '@chakra-ui/react';


const Header = (props) => {
    const { cart, setCart, order, setView, view, storefrontData,shop,cartOpen,setCartOpen  } = useContext(StorefrontContext);
    const { headerHeight, header_pic, bgYPos, avatar_pic,} = props.conf
    const { email, isRexPlayer } = props
    const [ loadImg, setLoadImg ] = useState()
  
    useEffect(()=>{
      console.log()

      window.addEventListener('load',()=>{
        const headerDiv = document.getElementById('shop-header')
        setLoadImg(header_pic)
        headerDiv?.classList.add('header-init')
      })
    },[storefrontData,shop])

  return (
    <Box //component='image'
        id='shop-header'
        className=''
        src={ loadImg }
        sx={{
            position: 'relative',
            borderRadius:'20px',
            width: '100%', 
            minWidth: '300px',
            // height: 0,
            minHeight: headerHeight,
            maxHeight: headerHeight,
            boxShadow:'0px 4px 5px 1px rgba(0,0,0,0.3)'
            // border: '1px solid #000'
            
            
        }}>
          {/* {header_pic ? '': } */}
            <div style={{zIndex:999999}}>
              <NavBar shop={props?.shop} email={email} isRexPlayer={isRexPlayer}/>
          </div> 
        <Tooltip title="home">

       <Avatar sx={{ 
            position: 'absolute',
            padding:'0.5rem',
            left:'50%',top:'60%',
            transform: 'translate(-50%,-50%)',
            bgcolor: '#868686', 
            width:'150px', 
            height:'150px',
            border:'3px solid #000',
            // marginBottom:'-1rem', 
            backgroundColor:'#fff',
            borderRadius:'10px',
            zIndex:0,
            cursor:'pointer',
            boxShadow:'0px 0px 15px 5px rgba(0,0,0,0.8)'}} 
            src={avatar_pic}
            onClick={()=>{setView('home')}}
            >
            
        </Avatar>
        </Tooltip>
       <Box 
        // component='img'
        sx={{
            // position: 'relative',
            position: 'absolute', top:'0px', left:'0px', 
            margin:'auto',
            backgroundColor:'rgba(203, 244, 245, 0.8)',
            maxWidth:'100vw',
            minWidth:'300px',
            width:'100%',height:'auto',minHeight: headerHeight,zIndex:'-1', opacity:'1',
            boxShadow:'inset 0px -3px 10px 5px rgba(255,255,255,0.8),',//,0px -2px 5px 3px rgba(0,0,0,0.5)'
            backgroundImage:`url("${header_pic}")`,//backgroundAttachment:'fixed',
            backgroundRepeat: 'no-repeat',
            backgroundPositionY:`${bgYPos}px`,
            backgroundSize: 'cover',
            backgroundPosition:`fixed`,
            borderRadius:'0px 0px 10px 10px',
        
        }}>  
       
    </Box>
      <CheckoutModal 
      cartOpen={cartOpen} 
        setCartOpen={props.setCartOpen} modalView={'cart'}  subView={'cart'}
        cart={cart} shop={shop} setCart={setCart}
        />
    </Box>
    
  )
}

export default Header