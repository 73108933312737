import { Container, Modal,Box, Button,
     Input, Typography, TextField, Stack } from '@mui/material'
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom';

import CloseButton from '../../../components/CloseButton'

const GameModal = (props) => {
    const { gameModalOpen,setGameModalOpen } = props
    const [game, setGame] = useState()
    const games = [
        {
            title: 'Rex Entropy Through the Neurodiverge',
            sub_title: 'An Augmented Intro',
            src:'https://aj.rexentropy.com/game',
            icon:<img src='/assets/Rex_Entropy_Icon_Glow.png' width="50px" height="auto" style={{margin:'auto'}}/>,
            description:'An AR bubble popping game centered around the Rex / AJ character backstories.',
            status:'demo'
            
        },
        {
        title: 'Rex Entropy Through the Neurodiverge',
        sub_title: 'Break the Dungeon: SubEarth',
          src:'https://subearth.rexentropy.com/',
          icon:<p style={{fontSize:'2.5rem',alignText:'center'}}>🥪</p>,
          description:'A sandwich making game focused on efficiency.',
          status:'beta'
        },
    ]
  return (
     <>
        <Modal 
            // open={true}
            open={gameModalOpen}
            onClose={()=>setGameModalOpen(!gameModalOpen)}
            >
            <Container sx={{zIndex:999}}>
                <Box sx={{ 
                    display:'flex',
                    flexDirection:'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height:'100%',
                    backgroundColor:'rgba( 0, 0, 0, 0.8)',
                    backdropFilter:'blur(5px)',
                    borderRadius:'10px',
                    border:'2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    justifyContent:'center',
                    textAlign:'center',
                    alignItems:'center',
                    gap:'0.5rem',
                    padding:'0.5rem',
                    color:'#FFF'
                    }}>
                        <Box sx={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
                            <CloseButton func={setGameModalOpen} val={gameModalOpen}
                            />
                        </Box>
                         
                         {games &&  <Box sx={{display:'flex',flexDirection:'column',  background:'#000',
                                boxShadow:'0 0 10px rgba(0, 255, 0 ,0.5)',
                                overflow:'hidden',overflowY:'scroll',
                                width:'340px', height:'450px', gap:'0.5rem', color:'#00FF00', borderRadius:'5px'}}>
                           <Box sx={{position:'sticky',top:0, left:0, zIndex:999999999, width:'100%', height:'1.5rem',background:'#F6F6F6'}}>
                            <Typography sx={{color: '#858585'}}>Select a Game</Typography>
                           </Box>
                        <Box sx={{padding:'0.5rem', margin:'auto',width:'80%',textAlign:'left'}}>
                      <b style={{color:'magenta'}} >{games[0]?.title}</b>
                      <p style={{fontSize:'0.75rem'}}>
                       Segments of a larger game concept in the form of mini-games, centered around my personal journeys with <b><Link  href="https://tourette.org/about-tourette/overview/what-is-tourette/"  target="_blank" style={{color:'magenta'}}>Tourette Syndrome</Link></b>  and what they have to offer. 
                      </p>
                      {/* <p style={{fontSize:'0.75rem'}}>
                       I would love the opportunity to pan out the concept with a team to create an engaging experience that provides an informative perspective 
                      </p> */}
                        <Box sx={{display:'flex', flexDirection:'column',
                            flexWrap:'wrap', alignItems:'center',
                            alignContent:'center',// border: '1px solid #FFF',
                            margin:'auto',
                            width:'100%', gap:'0.5rem', padding: '0.5rem',}}>
                            {games?.map((game,i)=>{
                                return(
                                <Link to={game?.src} target='_blank' 
                                key={i}
                                className={(i+1)%2 ?'blink':'blink-2'}
                                style={{textDecoration:'none',
                                    boxShadow:'0px 0px 15px 5px rgba(0, 255, 0 ,0.5),inset 0px 0px 5px 3px rgba(0, 255, 0 ,0.2)',
                                    border:'1px solid #00FF00',
                                    borderRadius:'5px',
                                    display:'flex',
                                    textAlign:'left',
                                    gap:'0.5rem',
                                    padding:'0.5rem',
                                    color:'#00FF00', 
                                    width:'100%',
                                    height:'100px',
                                    }}>
                                    <Box sx={{display:'flex',
                                        width:'20%',
                                        height:'50%',
                                        flexDirection: 'column',
                                        border:'3px solid #00FF00', 
                                        boxShadow:'0px 0px 5px 2px rgba(0, 255, 0 ,0.5),inset 0 0 3px 2px rgba(0, 255, 0 ,0.2)',
                                        borderRadius:'8px', 
                                        background:'#000',
                                        padding: '0.25rem',
                                        cursor:'pointer',
                                        textAlign:'center',
                                        justifyContent:'center',
                                        alignSelf:'center',
                                        '&:hover': {background:'rgba(144, 254, 195,0.5)'},
                                    }}
                                        // onClick={()=>{
                                        //     setGame(game)
                                        // }}
                                        >
                                        {game?.icon}
                                    </Box>
                                    <Stack sx={{ alignSelf:'center',}}>
                            <b style={{fontSize:'0.9rem',color:'magenta' }}>{game?.sub_title}</b>
                                       <p style={{ width:'90%', padding:'0.25rem',fontSize:'0.75rem'}}>
                                        {game?.description}
                                        </p> 
                                    <span>
                                        <b>status: </b>
                                        {game?.status}
                                    </span>
                                    </Stack>
                                </Link>
                                )
                            }) }
                         </Box>
                        </Box>
                       </Box>}
                       {/* <iframe src="https://subearth.rexentropy.com" title="Embedded React App" /> */}
                       {/* <iframe src="https://subearth.rexentropy.com" title="Embedded React App" /> */}
                      {/* {game && <iframe src={game?.src} style={{width:'100%', height:'100%'}}
                       title="Embedded React App" />}
                   */}
                    </Box>                   
            </Container>
        </Modal>
        
    </>
  )
}

export default GameModal

 
