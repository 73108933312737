import { Container, Modal,Box, Button, Input, 
    Card,Divider,
    Typography, TextField, Stack, Switch } from '@mui/material'
import React, { useState, useContext,useEffect,useRef } from 'react'
import CloseButton from '../../../components/CloseButton'
import StreamComponent from './StreamComponent'
import { AppContext } from "../../../context/AppProvider";
import Login from '../../../components/auth/Login';
import io from 'socket.io-client';
import WebRTCComponent from './WebRTCComponent';
import EnvRoute from '../../../middleware/envRoute';
import * as API from '../../../utils/APICalls'
import { Chat, LiveKitRoom, ParticipantTile,ChatEntry } from '@livekit/components-react';
// import MeteredFrame from '@metered/sdk-frame';

const StreamModal = (props) => {
     const frameRef = useRef(null);
    const { artist, setArtist,streamModalOpen, setStreamModalOpen, 
        listener,
        isStreaming, setIsStreaming  } = props
     const { appData,userData, setUserData,actionText, setActionText} = useContext(AppContext);
const [ showLogin, setShowLogin ] = useState(false)
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const peerConnectionRef = useRef();
  const socketRef = useRef();

    // useEffect(()=>{
    //      var frame = new MeteredFrame(); 
    //         frame.init({
    //         roomURL: "rexentropy.metered.live/cktcs4oqzh",
    //     }, frameRef.current);

    // },[])
     const hostList = ['66ee08d3433aa1f7cf08184b','66e8785fdc31b6b4a973ab3b','65f056671267a2925b3f6354']
  const hostCheck = hostList.includes(listener?._id)
    useEffect(()=>{

    },[userData])
  return (
     <>
        <Modal 
            open={streamModalOpen}
            onClose={()=>setStreamModalOpen(!streamModalOpen)}
            >
            <Container sx={{zIndex:999}}>
                <Box sx={{ 
                    display:'flex',
                    flexDirection:'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height:'98vh',
                    overflow: 'hidden',
                    overFlowY:'scroll',
                    backgroundColor:'rgba( 0, 0, 0, 0.8)',
                    backdropFilter:'blur(5px)',
                    borderRadius:'10px',
                    border:'2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    justifyContent:'center',
                    textAlign:'center',
                    alignItems:'center',
                    gap:'0.5rem',
                    padding:'0.5rem',
                    color:'#FFF',
                    }}>
                        <Box sx={{position:'absolute', top:'1.75rem', right:'0.5rem',zIndex:99999999}}>
                            <CloseButton func={setStreamModalOpen} val={streamModalOpen}
                            />
                        </Box>
                       {hostCheck && 
                       <Box sx={{position:'absolute', top:'1.75rem', left:'0.75rem',zIndex:99999999}}>
                       <Card sx={{//position:'absolute',left:'50%',transform:'translate(-50%,0)',top:'0.75rem',
                       padding:'0.5rem',textAlign:'center', bgcolor:'rgba(0,0,0,0.5)',backdropFilter:'blur(5px)',
                        margin:'auto',width:'fit-content',borderRadius:'10px', border:`1px solid ${artist?.stream?.isStreaming ? '#00FF00': '#FF0000'}`}}>

                        <b style={{color:artist?.stream?.isStreaming ? '#00FF00': '#FF0000'}}>streaming</b>
                        <Divider sx={{borderColor: artist?.stream?.isStreaming ? '#00FF00': '#FF0000'}}/>
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent='center'>
                                <Typography sx={{color:'#FF0000'}}>off</Typography>
                                <Switch defaultValue={artist?.stream?.isStreaming} inputProps={{ 'aria-label': 'ant design' }}
                                    onChange={async(e)=>{
                                        // toggleStorefront(currentShop)
                                        try{

                                            const data = await API.updateArtist({_id: artist?._id,stream:{
                                                 ...artist.stream,
                                                 isStreaming:!artist.stream.isStreaming
                                             }})
                                             if (data){
                                                 setArtist(data)
                                             }
                                            }catch(err){console.log(err)}
                                             }} 
                                        
                                             checked={artist?.stream?.isStreaming} />
                                    
                                <Typography sx={{color:'#00FF00'}}>on</Typography>
                        </Stack>
                        </Card>
                        </Box>
                        
                        }
                        
                      <Container sx={{display:'flex', position:'relative',
                         flexDirection:'column', justifyContent:'center', gap:'0.5rem'}}>
                        { !artist?.stream?.isStreaming && ( 
                            <Stack sx={{position:'sticky', top:'35%', }}>
                                <Typography variant='h3'>
                                    Stream currently unavailable.
                                </Typography>
                                <Typography  variant='caption'>
                                    
                                    ( Check back soon! )
                                </Typography>
                            </Stack>
                        )}
                        {/* {!userData?._id && <Box
                        className='color-grad-blue-border'
                            sx={{
                                margin:'auto',
                                // position:'absolute',
                                // top:'50%',
                                // left:'50%',
                                // transform: 'translate(-50%,-50%)',
                                zIndex:999999,
                                width:'60%',
                                maxWidth:'400px',
                                backgroundColor:'rgba(255,255,255,0.7)',
                                border:'2px solid #000',
                                borderRadius:'10px',padding:'0.5rem'
                                }}>
                                
                                    {showLogin?<Login view='stream'/>:
                                    <Box sx={{cursor:'pointer'}}
                                    onClick={()=>setShowLogin(!showLogin)}
                                    >
                                     <Typography>
                                        Host?
                                        </Typography>   
                                   (click to login)
                                    </Box>}
                                </Box> 
                            } */}
                        {/* {artist?.owner === userData?._id &&  */}
                        {/* <Container sx={{position:'relative',padding:'0.5rem',height:'100vh',width:'100%'}}> */}
                            {/* <StreamComponent isStreaming={isStreaming} setIsStreaming={setIsStreaming}/> */}
                            <WebRTCComponent 
                                listener={listener}
                                artist={artist}
                                isStreaming={isStreaming} 
                                setIsStreaming={setIsStreaming}/>

                        {/* <LiveKitRoom url={EnvRoute[0]+'/socket.io'} token="YOUR_LIVEKIT_TOKEN"> */}
                           {/* <Chat>
                           <ChatEntry />
                           </Chat> */}
                            {/* <ParticipantTile />  */}
                        {/* </LiveKitRoom> */}
                        {/* <iframe> */}
                            {/* <div id="metered-frame" ref={frameRef} style={{ width: '100%', height: '500px' }} /> */}
                       
                        {/* <script>
                           
                        </script> */}
                        {/* </iframe> */}
                                
                        {/* </Container> */}
                        {/* } */}
                        </Container>   

                    </Box>                   
            </Container>
        </Modal>
        
    </>
  )
}

export default StreamModal