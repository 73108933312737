import ShoppingCart from '@mui/icons-material/ShoppingCart'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Box, Chip, Divider, Icon, ListItem, Stack, Tooltip, Typography } from '@mui/material'
import React, {useState, useContext} from 'react'
import useWindowDimensions from "../../../hooks/windowDimensions";
import Close from '@mui/icons-material/Close';
import LyricsIcon from '@mui/icons-material/Lyrics';
import ShareIcon from '@mui/icons-material/Share';
import PinModal from './PinModal';
import * as Brands from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddComment, Favorite, FavoriteBorder } from '@mui/icons-material';
import * as API from '../../../utils/APICalls'
import * as helpers from '../../../utils/helpers'
import ShareModal from './ShareModal';
import { Link } from 'react-router-dom';
import { StorefrontContext, StorefrontProvider }  from "../../../context/StorefrontProvider";
import PersonIcon from '@mui/icons-material/Person';
import HeadphonesIcon from '@mui/icons-material/Headphones';
// import PandoraIcon from '../../../../public/assets/pandora-icon.svg'
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CustomProgressBar from './CustomProgressBar';

const PlaylistCard = (props) => {
    const {  currentProduct, setView,
        
          setCurrentProduct,storefrontData, updateStorefrontData,
        } = useContext(StorefrontContext);

    const{item, index, setAudioObj,audioObj, playAudio, setPlayAudio,// setShareModalOpen,shareModalOpen,
        toggleAudio,colors, albums, trkNum, setTrkNum, listEmail,
        saveListen, duration,  listener, setListener, onSeek,rankedTracks,
        setShopModalOpen,
    playAlbum, setPlayAlbum, albumNav , setAlbumNav, currentTime, setCommentModalOpen, setTimestamp} = props

    const [ showInfo, setShowInfo ] = useState(false)
    const [ pinModalOpen, setPinModalOpen ] = useState(false)
    const [ shareModalOpen , setShareModalOpen] = useState(false)
    const { height, width } = useWindowDimensions();

   const flavors = ['psychedelic', 'funk', 'punk', 
    'hip-hop', 'metal','rock','' ,'soul','pop','bluesy','electronic', 'noise', 'spoken word','teleplay', 'alt','🤷 misc.','dance',]
   
    const trackStatusList = {
        'jam':'#9E9E9E',
        'demo':'#9E9E9E',
        'instrumental':'#FFC107',
        'writing':'#FFEB3B',
        'vocal draft':'#03A9F4',
        'vocal comp':'#3F51B5',
        'complete':'#CDDC39',
        'streaming':'#CDDC39',
    }


    const brands = ['facebook','instagram','linkedin','upwork', 'github', 'gitlab', 'twitter', 'bandcamp','soundcloud','spotify','youtube','vimeo','amazon','tidal','youTube',
        'airbnb','uber','lyft','stackoverflow','stackexchange','pinterest','skype','etsy','codepen', 'dropbox', 'googleplay','imdb','npm','meetup','itunes','cashApp','venmo','paypal','pandora','deezer','linktree','xbox','playstation','steam','snapchat','slack','kickstarter','patreon','stripe', 
        'discord','wordpress','tiktok','whatsapp',
        'windows','apple','shopify','twitch','tumblr','trello','unity','wikipedia'
    ]

    function hasValue(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== undefined && obj[key] !== null) {
      return true;
    }
  }
  return false;
}
    const secsToMins = (duration)=>{
        let mins = duration / 60
        let secs = duration 
         mins = mins?.toString()?.split(".")[0]
        return `${duration > 60 ? mins : '00'} : ${duration % 60 < 10 ? '0' + duration % 60: duration % 60 }`
    }
        const linkIconCheck = (l) =>{
        // console.log('[linkIconCheck] brands?.some((k)=>l?.includes(k.toString))',brands?.find((k)=>l?.includes(k?.toString())))
        let result = brands?.find((k)=>l?.includes(k?.toString()))
        if(result){
            return result?.charAt(0).toUpperCase() + result?.slice(1);
        }else if(l?.includes('youtu.be')){
          return 'Youtube'
        }else{ return false}
    }

    const totalTrackPlays = (track) =>{
        let playCount = 0
        if (track && track.listeners && Array.isArray(track.listeners)) {
            playCount = track.listeners.reduce((total, listener) => {
                if (listener.plays && Array.isArray(listener.plays)) {
                    return total + listener.plays.length
                }
                return total;
            }, 0);
        }
        return playCount
    }

    // const trackCompletionPercentage = (track) =>{
    //     let playDuration = 0
    //     if (track && track?.listeners && Array.isArray(track.listeners)) {
    //         playDuration = track?.listeners.reduce((total, listener) => {
    //             if (listener?.plays && Array.isArray(listener?.plays)) {
    //                 // console.log('[trackCompletionPercentage]:',track,listener?.plays)
    //                 return total + listener?.plays.reduce((sum, play) => sum + (typeof play.length === 'number' ? play.length : 0), 0);
    //             }
    //             return total
    //         }, 0);
    //     }
        
      
    //     return( playDuration/totalTrackPlays(track)/track?.duration) * 100
    // }
    const trackCompletionPercentage = (track) => {
    if (!track || !track.listeners || !Array.isArray(track.listeners) || !track.duration) {
        return 0; // or some default value
    }

    let playDuration = track.listeners.reduce((total, listener) => {
        if (listener.plays && Array.isArray(listener.plays)) {
            return total + listener.plays.reduce((sum, play) => {
                return sum + (typeof play.length === 'number' ? play.length : 0);
            }, 0);
        }
        return total;
    }, 0);

    const totalPlays = totalTrackPlays(track); // Assuming this function exists and works correctly

    if (totalPlays === 0 || track.duration === 0) {
        return 0; // or some default value
    }

    return (playDuration / totalPlays / track.duration) * 100;
};

    const trackShopRanking = (albums,track) =>{
        let ranking = 0
        if (track && albums && Array.isArray(albums)) {
            ranking = track.listeners.reduce((total, listener) => {
                if (listener.plays && Array.isArray(listener.plays)) {
                    return total + listener.plays.length
                }
                return total;
            }, 0);
        }
      let score = track?.listeners?.length > 0? (totalTrackPlays(track) * 0.5)/( track?.listeners?.length  * 0.25) *  (trackCompletionPercentage(track)  * 0.25)* 100 :0
        return score
    }

    const updateUserLikes = async() =>{
        try{
            const likes = listener?.likes
             let index = likes?.indexOf(item?._id)
             if (index > -1) {
                     likes?.splice(index, 1);
                    // }
                }else{
                    //TODO Add listenerId from comment likes list
                    likes.push(item?._id)
  
                }
        const res = await API.updateListener({
            ...listener,
            likes: likes
            })
                if(res){
                    setListener(res)
                }
        }catch(err){
        console.log(err)
      }


    }
    const handlePairedProduct = async () =>{
            try{
                console.log('fetched item?.paired_merch_id:',item?.paired_merch_id)
                const prod = await API.fetchItem(item?.paired_merch_id)
                console.log('fetched prod:',prod)
                if(prod){
                    setCurrentProduct(prod)
                    setView('product')
                    await updateStorefrontData({
                        ...storefrontData,
                        currentProduct
                    })
                    setShopModalOpen(true)
                    console.log('fetched CurrentProduct:',currentProduct)
                }
        
                // window.open(`https://parallaxr.shop/${listEmail}/${item?.paired_merch_id ?item?.paired_merch_id:''}`,'_blank')
            }catch(err){console.log(err)}
                    
    }

     const handleLikeToggle = async () =>{
            await updateUserLikes()
           try{
            let list = item?.likes
            let index = list?.indexOf(listEmail)
    
            //   if(list?.includes(listEmail)){
                //TODO Remove userId from comment likes list
            if (index > -1) {
                    list?.splice(index, 1);
                // }
            }else{
                //TODO Add userId from comment likes list
                list.push(listEmail)
                
            }
    
            const res = await API.updateTrack({
                ...item,
                likes: list
            })
            if(res){
                
            }
            }catch(err){
        console.log(err)
      }

    }

    const itemStyles = {
        display:'flex',
        flexDirection: 'column',
        position: item?._id === playAudio?._id? 'sticky':'relative',
        top: 0,
        padding:'0.5rem',
        width:'99%',
        cursor:'pointer',
        gap:'0.5rem',
        // height: showInfo ?'fit-content':'4.5rem',
        height: showInfo ?'fit-content':'fit-content',
        // minHeight:'50px',
        color: colors.main,
        fontFamily: 'Gilroy, sans-serif',
        margin: '0.5rem auto',
        outline:'1px solid #757575',
        boxShadow:playAudio?._id === item?._id?'0px 0px 8px #00ff04':'',
        borderRadius:'10px',
        '&:hover': {borderRadius:'10px',
            // outline:'1px solid rgba(144, 254, 195,0.5)',
            background:'rgba(144, 254, 195,0.5)'
        }
            }
  return (
    <ListItem sx={itemStyles}
        className={`${item?.paired_merch_id ? 'color-grad-blue-border':''}`}
            key={index}
            onClick={(e)=>{
                // setShowInfo(!showInfo)
                 setAudioObj(playAlbum[index])
                console.log('current audio:',audioObj)
            }}>
                {playAudio?._id === item?._id?<span
                className='blink'
                style={{
                       position:'relative',
                       // left:'50%',
                       // top:'0.5rem',
                       width:'100%',
                       alignItems:'center',
                       alignContent:'center',
                       justifyContent:'right',
                       margin:'0.25rem auto', textAlign:'right',
                       // padding:'0.25rem',
                       // boxShadow:playAudio?._id === item?._id?'inset 0px 0px 5px #00ff04':'0px 0px 3px rgba(0,0,0,0.3)',
                       // background:'rgba(0,0,0,1)',
                       // color:'#FFF',
                       // border:'1px solid #868686',
                       // borderRadius:'0 0 5px 5px', 
                       //  background:'rgba(144, 254, 195,0.5)',
                       fontSize:'0.85rem',

                       }}>
                        {/* <Box style={{position:'relative',borderRadius:'100%',width:'0.5rem',height:'0.5rem', background:'#00FF00'}}>&nbsp;</Box> */}
                       <b>now playing</b>
                       </span>:''}
                  { item?._id === playAudio?._id && <CustomProgressBar className='custom-progress-bar'
                        setTimestamp={setTimestamp}
                        setCommentModalOpen={setCommentModalOpen}
                        currentTime={currentTime}
                        markers={playAudio?.comments}
                        duration={playAudio?.duration}
                         onSeek={onSeek}
                        /> }
                <ShareModal
                    track={item}
                    album={albums[albumNav]}
                    listener={listener}
                    setListener={setListener}
                    shareModalOpen={shareModalOpen} 
                    validateEmail={helpers.validateEmail}
                    setShareModalOpen={setShareModalOpen}/>
                    <Box 
                        sx ={{ display:'flex', width:'100%', 
                        alignItems:'center',
                        justifyContent:'space-between'}}>
                            <Tooltip title='play'>

                            <PlayCircleIcon
                            className='button-woddle'
                                sx={{
                                cursor:'pointer',
                                color: colors?.main
                            }}
                            onClick={(e)=>{
                                try{
                                    if(duration >=5){
                                     saveListen(true,'other track')
                                    }
                                    setTrkNum(index)
                                    setPlayAlbum(albums[albumNav])
                                    setAudioObj(albums[albumNav]?.tracks[index])
                                    setPlayAudio(albums[albumNav]?.tracks[index])
                                
                                toggleAudio()
                            
                                }catch(err){console.log('Track Clkic]:',err)}
                            }}
                            />
                        </Tooltip> 
         <Stack sx={{ width:'90%', gap:'0.25rem'}}>
                            {/* {playAudio?._id === item?._id?<b style={{
                                // position:'absolute',
                                // left:'50%',
                                // top:'0.5rem',
                                margin:'auto', textAlign:'center',
                                padding:'0.25rem',
                                boxShadow:playAudio?._id === item?._id?'inset 0px 0px 5px #00ff04':'0px 0px 3px rgba(0,0,0,0.3)',
                                background:'rgba(255,255,255,0.4)',
                                border:'1px solid #868686',fontSize:'0.85rem',
                                borderRadius:'50px' }}>now playing</b>:''} */}
            <Typography variant='p'
            sx={{display:'flex',color: colors?.accent, width:'100%',fontSize:'0.9rem',alignItems:'center',
            gap:'0.5rem',alignContent:'center'}}>
                 <b>  {index + 1}. {item?.name }</b>  ( {helpers.secsToMins(item?.duration)} ) {item?.isExplicit && 
                <Tooltip title="explicit">

                    <span style={{backgroundColor:'black', color:'#FFF', 
                    fontWeight:'bold', 
                    textAlign:'center',
                    alignContent:'center',
                    alignItems: 'center',
                    justifyContent:'center',
                    fontSize:'.8rem',
                    borderRadius:'5px',
                    // padding:'0.125rem', 
                    width:'1rem',
                    height:'1rem'
                    }}>E</span>
                </Tooltip>
                        }
                         <Tooltip title='share track'>
                <Box  className='blink-2'
                    sx={{color: colors?.main,}} >
                    <ShareIcon 
                        className='button-woddle'
                        sx={{cursor:'pointer'}}
                        onClick={()=>{
                        setShareModalOpen(true)
                        }} />
                </Box>
                </Tooltip >
                        {!showInfo   ?
            <Tooltip  className='blink' title='lyrics' sx={{ color:"#757575",}}>
                <LyricsIcon 
                    className='button-woddle' 
                    onClick={(e)=>{
                    setShowInfo(!showInfo)}}
                     />
                </Tooltip>
                 :
                 <Tooltip title='close'>

                     <Close 
                     className='button-woddle'
                     onClick={(e)=>{
                        setShowInfo(!showInfo)
                    }}
                       
                     />
            </Tooltip>
                 }
                  <Box sx={{display:'flex',
        // right:'0.5rem', 
        // position:'absolute',
        //   top:'2.5rem', //gap: '0.125rem' 
         
            alignContent:'center', alignItems:'center',
            textAlign:'center',justifyContent:'center',}}>
              {item?.paired_merch_id && <Tooltip title='paired merch'>
            <Box className='blink'
                 sx={{width:'2rem' }}>
                    {/* <Link to={`https://parallaxr.shop/${listener?.email}/${item?.paired_merch_id ?item?.paired_merch_id:''}`} target='_blank' > */}
                    <img
                    src={'/assets/ShirtIcon_cer.svg'}
                    className='constant-woddle'
                    style={{cursor:'pointer',width:'1.75rem'}}
                    onClick={handlePairedProduct} />
                    {/* </Link> */}
            
            </Box>
            </Tooltip >}

       

        
        </Box>
            </Typography>
            <Typography variant='p'
            sx={{color: colors?.accent, width:'100%',fontSize:'0.9rem',}}>
                    {item?.subtitle ? item?.subtitle: <>&nbsp;</>}
            </Typography>
            <Typography variant='p'
            sx={{color: trackStatusList[item?.status], 
                padding:'0.125rem 0.25rem',
                width:'fit-content',
                minWidth: '100px',
                fontSize:'0.9rem',
               
                backgroundColor:'rgba(176,255,252,0.2)',
                boxShadow:`inset 0 0 3px 1px ${trackStatusList[item?.status]}40,0px 0px 5px 2px ${trackStatusList[item?.status]}40`,
                border:`1px solid ${trackStatusList[item?.status]}`, 
                borderRadius:'3px'}}>
                  <b>status</b> | {item?.status}
            </Typography>

             {hasValue(item?.streaming) && (<Box sx={{position:'relative',//bottom:'0px',
                    display:'flex',
                    //border:'1px solid red', 
                        minHeight:'1.5rem',
                        alignContent:'center',
                        gap:'0.5rem',
                        padding:'0.125rem',
                        // justifyContent:'center',
                        width:'100%', margin:'auto'}}>

                    {Object.keys(item?.streaming).map((l,j)=>{
                    
                        let link = item?.streaming[`${l}`]
                        // console.log('[profile] link:', link)
                       if(linkIconCheck(link)){
                            let iconName = linkIconCheck(link)
                            // console.log('iconName:', iconName)
                            return (
                                <Tooltip title={iconName} key={j}>

                                    <div
                                    className='button-woddle'
                                    style={{
                                        // display:'flex',
                                        color:'#000',  
                                        width:'1.5rem',height:'1.5rem',
                                        '&:hover': {background:'rgba(144, 254, 195,0.5)'},
                                        // border:'1px solid blue', 
                                        borderRadius:'3px',
                                        alignContent:'center',
                                        minWidth:'1rem',minHeight:'1rem'}} //key={i}
                                        
                                        key={j}
                                        > 
                                    <a 
                                    style={{
                                        // display:'flex',
                                        color:'#000',  
                                    
                                        // border:'1px solid blue', 
                                        borderRadius:'3px',
                                        alignContent:'center',
                                        minWidth:'1rem',minHeight:'1rem'}} //key={i}
                                        href={link}
                                        target='_blank'
                                        key={j}
                                        > 
                                        {iconName !== 'Pandora' && <FontAwesomeIcon icon={Brands['fa'+ iconName]} key={j} />}
                                        {iconName === 'Pandora' && <img style={{height:'1.2rem'}}
                                        src={'/assets/pandora-icon.svg'} key={j} alt='pandora' />}
                            
                                    </a>
                                    </div>
                                </Tooltip>
                           )
                        }
        

                })}
            </Box>)
                }
                        {/* <Divider /> */}  
         </Stack>

<Stack sx={{gap:'0.5rem', padding:'0.5rem',width:'3rem'}}>
       
        <Stack sx={{gap:'0.125rem', width:'3rem',
        alignItems:'center',
        justifyContent:'center',
        alignContent:'center',
            // position:'absolute', right:'0.5rem', top:'0.5rem',
            fontSize:'0.75rem', alignItems:'center'}}
             onClick={(e)=>{
                setCurrentProduct(item)
               updateStorefrontData({
                    ...storefrontData, 
                    currentProduct
               })
                setPinModalOpen(true)
            }}
            >
            <ShoppingCart 
                className='button-woddle'
                sx={{fontSize:'0.85rem', color:colors?.main}}/>
                <Typography sx={{
            fontSize:'0.75rem', alignItems:'center', overflow:'hidden',
            textOverflow:'none',
             whiteSpace:'no-wrap'}}>

           $ {item?.price} USD
                </Typography>
           
        </Stack>

       
         
         <Box 
         
         sx={{display:'flex',
        //  position:'absolute',
        //   right:'0.5rem', 
        //   bottom:'0.5rem', //gap: '0.125rem' 
          dispaly:'flex',
            alignContent:'center', alignItems:'center',
            textAlign:'center',justifyContent:'center',
            }}
            // >
            // <span 
            onClick={handleLikeToggle}>
               
                
                 {item?.likes?.length}&nbsp;{!item?.likes?.includes(listEmail)?
              <Tooltip title='like'>
                <FavoriteBorder className='button-woddle'/>
              </Tooltip>
            :
            <Tooltip title='liked'>

                <Favorite sx={{color:'red'}}/>
            </Tooltip>
            
            }
            {/* </span> */}
            {/* <AddComment /> */}
         </Box>
</Stack>
      
        </Box>    
           <Box sx={{position:'absolute', right:'0.75rem', bottom:'0.5rem',
                display: 'flex', flexWrap:'wrap', gap:'0.5rem',
                // border:'1px solid red', 
                marginTop:'1rem',
                justifyContent:'center',
                height:'50px',
                width:'150px',//maxWidth:'250px',minwidth:'150px', 
                padding:'0.25rem',
                fontSize:'0.8rem'
                }}>
                    {/* const  */}
       <Tooltip title='unique listeners' style={{display:'flex',gap:'0.25',textAlign:'center', justifyContent:'center',alignContent:'center',alignItems:'center', width:'4rem' }}>{item?.listeners?.length? item?.listeners?.length:'0'} <PersonIcon/> </Tooltip>
       <Tooltip title='total plays' style={{display:'flex',gap:'0.25',textAlign:'center', justifyContent:'center',alignContent:'center',alignItems:'center', width:'4rem' }}>{item?.listeners?.length? totalTrackPlays(item):'0'} <HeadphonesIcon /> </Tooltip>
       <Tooltip title='play percentage' style={{display:'flex',gap:'0.25',textAlign:'center', justifyContent:'center',alignContent:'center',alignItems:'center', width:'4rem' }}>{item?.listeners?.length? Math.round(trackCompletionPercentage(item))+' %':'0 %'}  <NetworkCheckIcon /> </Tooltip>
       <Tooltip title='ranking' style={{display:'flex',gap:'0.25',textAlign:'center', justifyContent:'center',alignContent:'center',alignItems:'center', width:'4rem' }}>{item?.listeners?.length? rankedTracks.findIndex(track => track.trackId === item?._id)+1:'0'}  <ShowChartIcon /> </Tooltip>
    
    </Box>
       { showInfo && <Box sx={{
            display:'flex',
            flexDirection:'column',
            gap:'0.5rem',
            positon:'relative',
            width:'90%', 
            minHeight:' 100px',
            maxHeight:'500px',
            // height:'50vh',
            height:'500px',
            overflowY:'scroll',
            // textAlign:'center',
            justifyContent:'center',
            alignContent:'center',
            padding:'0 1rem',
            margin: '0.5rem auto',
            border: `1px solid ${colors?.main}`,
            borderRadius:'5px',
            backgroundColor:'#FFF'
       }}>
             {/* <Divider /> */}
             <Box sx={{width:'100%', height:'100%', overflow:'hidden', overflowY:'scroll'}}>

             <Typography 
            //  variant='p' 
             sx={{ position:'relative',
                maxWidth:'400px', width:'100%',
            //  border:'1px solid red',
                    textAlign:"left", whiteSpace:'pre-wrap',padding:'0.5rem'}}>
                <Box sx={{height:'1rem'}}>
                    &nbsp;
                </Box>
                   <b style={{margin:'auto'}}>
                     Lyrics:
                    </b>
                    <br/>
                {item?.lyrics} 
             </Typography>
             </Box>
             <Divider />
             <Box sx={{maxWidth:'400px', display:'flex', flexDirection:'column', gap:'0.25rem'}}>

                {item?.details?.length &&  item?.details?.map((detail,i)=>{
                    return <div>{detail}</div>
                })} 
             </Box>
            
        </Box>}
        {/* <Divider sx={{width:'100%', margin: '1rem auto'}}/> */}
<PinModal pinModalOpen={pinModalOpen} setPinModalOpen={setPinModalOpen} item={item} album={albums[albumNav]}/>

<Box sx={{display:'flex', width:'100%', 
        gap:'0.5rem', alignContent:'center',alignItems:'center'}}>

<Box sx={{display:'flex', gap:'0.25rem', minHeight:'2rem',
     border:'1px solid #858585', padding:'0.5rem',marginLeft:'0.5rem',borderRadius:'10px',
     width:'50%',alignSelf:'start',
    background:'rgba(255,255,255,0.4)',
    backdropFilter:'blur(5px)',
    flexWrap:'wrap', alignItems:'center',fontSize:'0.7rem'}}>
        <b>flavors: </b>
        {item?.flavors?.length > 0 ?  item?.flavors?.map((flavor,j)=>{
            return (
                <Chip key={j} label={flavor} size='small'
                sx={{fontSize:'0.7rem'}}/>
                )
            })
        :'no flavors added'}
    </Box>
    
  
</Box>
                
    </ListItem>
  )
}

export default PlaylistCard