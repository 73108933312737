import React from 'react'
// import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';


const Copyright = (props) => {

    const {view, color,shadow} = props

    const contStyles = {
        position: 'relative',
        backgroundColor: '#FFF',
        color: color? color :'#000',
        fontFamily:'Gilroy; sans-serif',
        // height: '4.5rem',
        fontSize:'1rem',
        // padding: '0.125rem',
        alignItems: 'center',
        textAlign: 'center',
        
        borderRadius:  view==='signupSheet'?'10px 10px 0 0':'',
        // border: view ==='signupSheet'?'2px solid #000':'',borderBottom:'none',
        boxShadow: shadow?'0px -1px 7px 2px rgba(0,0,0,0.2)':'',
        border: view ==='signupSheet'?`3px ridge ${color ? color :'#000'}`:'',
        // borderRight: view ==='signupSheet'?'2px dotted #000':'',
        // borderTop: view ==='signupSheet'?'2px dotted #000':'',
        borderBottom: '0',
        // borderTop:'none'
        
    }
    const sectStyles = {
        position: 'relative',
        backgroundColor: '#FFF',
        color: '#000',
        height: '1.5rem',
        fontSize:'1rem',
        padding: '0.125rem',
        alignItems: 'center',
        textAlign: 'center'

    }

  return (
    <div style={contStyles} align="center" className='copyright'>
       { view ==='signupSheet' && <div style={{paddingTop:'0.75rem'}}>
            Powered by <b> <a  href="https://qaura.pro"  target="_blank" style={{textDecoration:'none'}}>QauRa</a></b>
        </div>}
       { view ==='signupSheet' && <div style={{fontSize:'0.75rem'}}>
            <b>Expand Your Brand, Grow Your Glow.</b>
        <br/>
            <span>Personal Marketing & Brand Engagement.</span>
        </div>}
        <div style={sectStyles} >
      {'© '}
      <a  href="https://rexentropy.com"  target="_blank">
        Rex Entropy LLC
      </a>{' 2023  - '}
      {new Date().getFullYear()}
      {'.'}
        </div>
        <br/>
    </div>
  );
}

export default Copyright