import { Box, Stack } from '@mui/material'
import React from 'react'
import SocialLinks from './SocialLinks'

const RPLogo = (props) => {
    const { artist } = props
  return (
 
        <Stack sx={{height:'150px',
            width:'100%',margin:'auto', zIndex:99999}}>
        
            <Box component={'img'}
                    className='lock-logo'
                    src={`/assets/RexRecord.png`}
                    // src={`/assets/Rex_Entropy_Icon_Glow.png`}
                    sx={{
                        position:'absolute',
                        top:'-25px',
                        // left:'50%',ck
                        margin:'auto',
                        width:'200px', 
                        // width:'100%', 
                        // maxWidth:'120px',
                        height:'auto', 
                        // opacity:'0.5',
                        // border:'3px solid #000',
                        borderRadius:'50%',
                        // backdropFilter:'blur(5px)',
                        // backgroundColor:'#000',
                        // boxShadow:'0 0 10px 4px rgba( 0,0, 0,0.2), inset 0 0 10px 3px rgba( 255, 255,255,0.3)',
                        position:'relative',
                        zIndex:0,
                        }}
                        />
                    
                <span style={{
                    position:'absolute',
                    bottom:'-1rem',
                    left:'25%',
                   transform:'translate(-10%,0)',
                    display:'flex',color:'#000' ,
                    backgroundColor:'#FFF',fontFamily:'Gilroy,sans-serif',
                    width:'fit-content',
                     boxShadow:'inset 0 0 8px 3px rgba( 0,0,0,0.2), 0px 2px 5px 1px rgba(0,0,0,0.2) ',
                    // boxShadow:'0 0 3px 2px rgba( 0, 0, 0, 0.1), inset 0 0 3px 1px rgba( 0, 0,0,0.3)',
                    border:'2px solid #000', borderRadius:'60px',padding:'0.5rem' }}>
                <b style={{ position:'absolute',left:'2rem', top:'0.25rem'}}>
                    The 
                </b>
                <h1>RexPlayer</h1>
                    <Box sx={{position:'absolute',
                     left:'50%',
                     bottom:'-2rem', 
                     transform: 'translate(-50%,0)',
                     margin:'0.5rem auto', 
                    padding:'0 0.125rem',borderRadius:'5px 5px ',
                      border:'1px solid #757575',
                      backgroundColor:'#FFF',
                      boxShadow:'inset 0 0 5px 1px rgba( 0,0,0,0.2), 0px 2px 5px 1px rgba(0,0,0,0.2) ',}}>
                        <SocialLinks artist={artist} />
                    </Box>
                </span>
            </Stack>
    
  )
}

export default RPLogo