import NavBar from './NavBar';
import Scan from './Scan'
import Menu from './Menu'
import CreateQR from './CreateQR';
import Modal from './Modal'
import Auth from './Auth'
import BecomeAPartner from './BecomeAPartner'
import { useCookies } from "react-cookie";
import AssetFieldComponent from "./AssetFieldComponent"
import { QRCodeContext } from "../context/QRCodeProvider";
import { Box, Button, Container, Divider, Paper, Select, TextField, 
    Stack,Typography, OutlinedInput, Accordion, AccordionSummary, AccordionDetails, 
    SwipeableDrawer} from '@mui/material'



import { AppProvider, AppContext, AppState } from "../context/AppProvider";


import React , { useState, useEffect, useContext } from 'react';
import LandingPage from './LandingPage';

import CloseIcon from '@mui/icons-material/Close';
import CloseButton from './CloseButton';
import Copyright from './Copyright';

const Home = () => {
    const { appData,updateAppData, modalOpen, userData, setUserData } = AppState();
    const { qrCodeData, updateQRCodeData } = useContext(QRCodeContext);

    const [cookies, removeCookie] = useCookies([]);
    const [homeModalOpen, setHomeModalOpen] = useState(true);
    
    const handleModalClose =()=>{
      setHomeModalOpen(false)
      updateAppData({
              ...appData,
              modalOpen:false,
              modalView:''
          })
    }
    const handleModalOpen =()=>{
      setHomeModalOpen(true)
    }
  // useEffect (()=>{//!-----🤷
  //   // let user
  //   //     if(!userData){
        
  //   //     try{

  //   //       user = JSON.parse(localStorage.getItem('QauRaUser'))
  //   //       if(user){
  //   //         setUserData({...user}) 
  //   //         updateAppData({...appData,userData})

  //   //         console.log('[App] local user:', user)
  //   //       }
  //   //       else{return}
  //   //     }catch(err){console.log(err)}
  //   //     }
  //       console.log('[App] local userData:', userData)
  //     console.log('[Home cookies]:', cookies.token)

  //   },[userData])//!-----🤷
  // useEffect (()=>{
   
  //   },[modalOpen,homeModalOpen])

  useEffect (()=>{
          // setGradVar(`linear-gradient(45deg,${gradVar1} 10%, hsl(${hue}deg 16.5% 58.49%) 50%, ${gradVar3} 90% ) no-repeat`)
      // clearInterval()
      // hueAnimator()
    },[appData,modalOpen])
  return (
    <div style={{position:'absolute',top:'0', left:'0',width:'100%',maxWidth:'100%', margin: 'auto'}}>
      {/* <div className='navbar'> */}
        <NavBar /> 
      {/* </div> */}
          {/* <div id="app-content"  //className ="color-grad-blue-border" 
          style={{height: '100%',

          background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6033950617283951) 18%, rgba(255,255,255,0.7160493827160495) 35%, rgba(223,252,255,0.5) 70%, rgba(254,255,255,0.0) 100%)'}}>
          
            */}
            
           { appData?.modalOpen &&
              <SwipeableDrawer
                onTouchStart={event => {
                  event.nativeEvent.defaultMuiPrevented = true
                }}
                anchor={'bottom'}
                open={ appData?.modalView ? appData?.modalView :false }
                onClose={() => {
                   updateAppData({
                        modalOpen:!modalOpen,
                        modalView:''
                    })
                  // setShowMetrics(!showMetrics)
                } }
                sx={{ display:'flex', flex:1, 
                  fontFamily:'Gilroy',maxWidth:'350px',overflow:'hidden',
                }}
              >
                {/* <Box sx={{ position: 'absolute',p:0,
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 'fit-content',
                    height:'fit-content', color:'#000' 
                  }}>  */}
                {/* <Box component={'img'}
                src='assets/BodyBG2.png'
                sx={{position:'absolute', top:'0rem', left:'0rem',width:'100%',height:'100%'}}
                >
                
                {/*                
                
                </Box> */}
                
                {/* <Box sx={{minWidth:'100vw', minHeight:'60vh',//backgroundColor:'#000',
                display:'flex',flexDirection:'column',justifyContent:'center'}}> */}
                {/* <h1 style={{color:"#000"}}>{appData?.modalView}</h1> */}
                {/* <div style={{zIndex:99999}}>
               <CloseButton 
                sx={{zIndex:999}}
                  func={updateAppData} val={{
                    ...appData,
                    modalOpen:!modalOpen,
                    modalView:''
                        }}/>
                   
                      
                   
                    </div> */}
                <Container style={{display:'flex', flex:1, width:'100%', height:'100%', 
                  margin:'0.5rem auto', overflow:'hidden',overflowY:'scroll'}}>
                    { appData?.modalView==='scan' && <Scan /> }
                    { appData?.modalView==='menu' && <Menu /> }
                    { appData?.modalView==='info' && <BecomeAPartner /> }
                    { appData?.modalView==='auth' && (<Auth/> )}
               </Container>
                {/* </Box> */}
                {/* </Box> */}
              </SwipeableDrawer>
               } 
              <LandingPage/>
             {/* <Copyright /> */}
              {/* <div style={{display:'flex',justifyContent: 'center',
              marginBottom:'1rem',width:'100%'}}>
                <AssetFieldComponent 
                  value={qrCodeData?.name}
                  object={qrCodeData}  name={'QRname'}
                  title_on={false}
                  key={1}
                  index={1}
                  view='home'
                  />
              </div>
                <CreateQR /> */}
             
         {/* <div style={{display:'flex', width:'100%', justifyContent: 'center', alignItems: 'center', color:'#000000', margin: '0.5rem auto'}}>
            © 2023 RexEntropyLLC
        </div>  */}
          {/* </div> */}
     
    </div>
  )
}
export default Home